<template>
    <el-dialog
        :title="$t('settings.add_payment_method')"
        :visible.sync="modalPaymentMethod"
        :before-close="closeModal"
        width="500px"
        top="40px"
        :close-on-click-modal="false"
        @closed="$emit('closed')"
    >
        <el-form ref="formData" class="px-16" :model="formData" label-position="top" :rules="rules">
            <el-form-item :label="$t('settings.payment_method')">
                <el-select v-model="formData.paymentMethod" class="w-full">
                    <el-option :label="$t('settings.bank_transfer')" value="transfer" />
                    <el-option label="Autogiro" value="autogiro" />
                </el-select>
            </el-form-item>
            <template v-if="formData.paymentMethod === 'transfer'">
                <el-form-item :label="$t('settings.bank_name')" prop="bankName">
                    <el-input v-model="formData.bankName" />
                </el-form-item>
                <el-form-item :label="$t('settings.bank_account_nr')" prop="bankAccountNr">
                    <el-input v-model="formData.bankAccountNr" />
                </el-form-item>
            </template>
            <template v-if="formData.paymentMethod === 'autogiro'">
                <el-form-item :label="$t('settings.autogiro_id')" prop="autogiroId">
                    <el-input v-model="formData.autogiroId" />
                </el-form-item>
                <el-form-item :label="$t('settings.autogiro_customer_id')" prop="autogiroCustomerId">
                    <el-input v-model="formData.autogiroCustomerId" />
                </el-form-item>
            </template>
        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button @click="closeModal">{{ $t('common.cancel') }}</el-button>
            <el-button type="primary" :loading="$wait.is('adding')" @click="addPaymentMethod">{{ $t('common.add') }}</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    props: {
        modalPaymentMethod: {
            type:    Boolean,
            default: false,
        },
    },

    data() {
        return {
            formData: {
                paymentMethod:      'transfer',
                bankName:           '',
                bankAccountNr:      '',
                autogiroId:         '',
                autogiroCustomerId: '',
            },
            rules: {
                bankName:           [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                bankAccountNr:      [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                autogiroId:         [{ required: true, message: this.$t('common.required'), trigger: 'change' }],
                autogiroCustomerId: [{ required: true, message: this.$t('common.required'), trigger: 'change' }],
            },
        };
    },

    methods: {
        addPaymentMethod() {
            this.$refs.formData.validate(async valid => {
                if (!valid) return false;

                this.$wait.start('adding');

                try {
                    await axios.post(`${process.env.VUE_APP_CORE_API_URL}/settings/payment_methods`, {
                        type:                 this.formData.paymentMethod,
                        bank_name:            this.formData.paymentMethod === 'autogiro' ? null : this.formData.bankName,
                        bank_account_nr:      this.formData.paymentMethod === 'autogiro' ? null : this.formData.bankAccountNr,
                        autogiro_id:          this.formData.paymentMethod === 'transfer' ? null : this.formData.autogiroId,
                        autogiro_customer_id: this.formData.paymentMethod === 'transfer' ? null : this.formData.autogiroCustomerId,
                    });
                    this.$wait.end('adding');
                    this.$notify.success({ title: this.$t('common.success') });
                    this.$emit('refresh');
                    this.closeModal();
                } catch (error) {
                    this.$wait.end('adding');
                }
            });
        },

        closeModal() {
            // axios.cancelAll();
            this.$emit('close');
        },
    },
};
</script>
